import React, { forwardRef } from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import {
  MAX_WIDTH,
  mqHandheld,
  mqTablet,
  BACKGROUND,
} from "components/index.css"
import BurgerMenu from "components/burger-menu"

const Header = forwardRef(({ siteTitle }, ref) => {
  const { logo } = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 364) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Container ref={ref}>
      <HomeLink to="/">
        <Img fluid={logo.childImageSharp.fluid} />
      </HomeLink>
      <BurgerMenu />
    </Container>
  )
})

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

const Container = styled.header({
  position: `fixed`,
  zIndex: 10,
  top: 0,
  left: 0,
  right: 0,
  width: `100%`,
  padding: `52px 75px 0`,
  display: `flex`,
  justifyContent: `space-between`,
  background: `rgb(${BACKGROUND})`,
  maxWidth: MAX_WIDTH + 150,
  margin: `auto`,

  [mqHandheld]: {
    padding: `23px 18px 0`,
    alignItems: `center`,
  },
  [mqTablet]: {
    padding: `50px 50px 0`,
    alignItems: `center`,
  },
})

const HomeLink = styled(Link)({
  width: 205,

  [mqHandheld]: {
    maxWidth: `50vw`,
    zIndex: 10,
  },

  [mqTablet]: {
    maxWidth: 268,
  },
})

import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "@emotion/styled"
import { a, useChain, useSpring } from "react-spring"
import { Link, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import {
  useAnimationFrame,
  useMeasure,
  useOnClickOutside,
  useWindowSize,
} from "helpers/hooks"
import { isMobileSafari } from "helpers/browser-detect"
import {
  FONT,
  MAX_WIDTH,
  desktopMaxBP,
  handheldBP,
  tabletMaxBP,
  mqHandheld,
  mqTablet,
  mqTabletHandheld,
  mqXLDesktop,
  sideMargin,
} from "components/index.css"

function getConfig(width, height) {
  switch (true) {
    case width <= handheldBP:
      return {
        open: { s: 400, t: -150, r: -165 },
        closed: { s: 32, t: 0, r: 0 },
      }
    case width <= tabletMaxBP:
      return {
        open: { s: 400, t: -150, r: -165 },
        closed: { s: 45, t: 0, r: 0 },
      }
    case width > desktopMaxBP: {
      return {
        open: {
          s: width * 3,
          t: -height / 2,
          r: -width * 1.25,
        },
        closed: { s: 45, t: 0, r: 0 },
      }
    }
    default:
      return {
        open: {
          s: width > height ? width * 2 : height * 2,
          t: -height / 2,
          r: -width / 2,
        },
        closed: { s: 45, t: 0, r: 0 },
      }
  }
}

const BurgerMenu = () => {
  const { height: winHeight, width } = useWindowSize()
  const [bind, { height }] = useMeasure()
  const { open, closed } = getConfig(width, winHeight)
  const containerRef = useRef(null)
  const circleRef = useRef(null)
  const menuRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)
  const [bgId, setBgId] = useState(null)
  const shouldChangeBg = useAnimationFrame(setBgId)
  const changeOpen = useAnimationFrame(setIsOpen)

  const { images } = useStaticQuery(graphql`
    query imgQuery {
      images: allFile(
        filter: {
          relativeDirectory: { eq: "backgrounds" }
          extension: { regex: "/(jpg)|(png)/" }
        }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 1080) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (window.location.pathname !== `/`) return
    const timer = setTimeout(() => {
      setIsOpen(true)
    }, 5000)
    return () => clearTimeout(timer)
  }, [])

  const imageMap = useMemo(() => {
    return images.edges.reduce((map, edge) => {
      const { name, childImageSharp } = edge.node
      map[name] = childImageSharp.fluid
      return map
    }, {})
  }, [images])

  const circleProps = useSpring({
    ref: circleRef,
    width: isOpen ? open.s : closed.s,
    height: isOpen ? open.s : closed.s,
    top: isOpen ? open.t : closed.t,
    right: isOpen ? open.r : closed.r,
  })

  const menuProps = useSpring({
    ref: menuRef,
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `translateX(0px)` : `translateX(50px)`,
    height: isOpen ? height : 0,
  })

  useChain(isOpen ? [circleRef, menuRef] : [menuRef, circleRef], [0, 0.2])

  useOnClickOutside(containerRef, () => changeOpen(false))

  function toggle(e) {
    e.stopPropagation()
    shouldChangeBg(null)
    changeOpen(!isOpen)
  }

  function handleOnMouseEnter(e) {
    if (isMobileSafari()) return // hack: this would mean double click in iOS
    const { id } = e.target
    shouldChangeBg(id)
  }

  function handleOnMouseLeave() {
    shouldChangeBg(null)
  }

  return (
    <Relative ref={containerRef}>
      <Circle ref={circleRef} style={circleProps} />
      {bgId && (
        <ImgWrapper>
          <Img fluid={imageMap[bgId]} />{" "}
        </ImgWrapper>
      )}
      <Button type="button" onClick={toggle} isOpen={isOpen}>
        <div />
        <div />
      </Button>

      <Menu onMouseLeave={handleOnMouseLeave} ref={menuRef} style={menuProps}>
        <div {...bind}>
          <NavLink
            id="navlink-1"
            onMouseEnter={handleOnMouseEnter}
            activeClassName="active"
            to="/nosotros"
            onClick={toggle}
          >
            nosotros
          </NavLink>
          <NavLink
            id="navlink-2"
            onMouseEnter={handleOnMouseEnter}
            activeClassName="active"
            to="/tranquilidad"
            onClick={toggle}
          >
            tranquilidad
          </NavLink>
          <NavLink
            id="navlink-3"
            onMouseEnter={handleOnMouseEnter}
            activeClassName="active"
            to="/servicios"
            onClick={toggle}
          >
            servicios
          </NavLink>
          <NavLink
            id="navlink-4"
            onMouseEnter={handleOnMouseEnter}
            activeClassName="active"
            to="/contacto"
            onClick={toggle}
          >
            &iquest;hablamos?
          </NavLink>
        </div>
      </Menu>
    </Relative>
  )
}

export default BurgerMenu

const Img = styled(BackgroundImage)({
  height: `100%`,
  backgroundColor: `rgb(158, 158, 159)`,
  opacity: 0.8,
})

const Circle = styled(a.div)({
  borderRadius: `50%`,
  background: `rgb(235, 195, 46)`,
  position: `absolute`,
  display: `inline-flex`,
  flexDirection: `column`,
  justifyContent: `center`,
  alignItems: `center`,
  zIndex: -1,

  [mqTabletHandheld]: {
    background: `rgba(235, 195, 46, 0.95)`,
  },
})

const Button = styled.button(({ isOpen }) => ({
  position: `relative`,
  border: `none`,
  background: `transparent`,
  borderRadius: `50%`,
  display: `flex`,
  flexDirection: `column`,
  justifyContent: `space-around`,
  cursor: `pointer`,

  height: 45,
  padding: `10px 12px`,
  [mqTablet]: {
    height: 45,
    padding: `10px 12px`,
  },
  [mqHandheld]: {
    height: 32,
    padding: `7px 9px`,
  },

  "> div": {
    borderRadius: 4,
    background: FONT,
    transition: `transform 0.3s linear`,
    position: `relative`,
    "&:first-of-type": {
      transform: isOpen ? `rotate(-45deg)` : `rotate(0)`,
    },
    "&:last-child": {
      transform: isOpen ? `rotate(45deg)` : `rotate(0)`,
    },

    height: 3,
    width: 21,
    transformOrigin: `19px`,
    [mqTablet]: {
      height: 3,
      width: 21,
      transformOrigin: `19px`, //1.3 ??
    },
    [mqHandheld]: {
      width: 14,
      height: 2,
      transformOrigin: `13.4px`,
    },
  },
  "&:focus": {
    outline: `none`,
  },
}))

const Relative = styled.div({
  position: `relative`,

  width: 45,
  height: 45,
  [mqTablet]: {
    width: 45,
    height: 45,
  },
  [mqHandheld]: {
    width: 32,
    height: 32,
  },
})

const ImgWrapper = styled.div({
  position: `fixed`,
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
})

const Menu = styled(a.nav)({
  position: `fixed`,
  overflow: `hidden`,
  height: 0,
  top: 0,
  right: sideMargin.desktop + 12,
  bottom: 0,
  margin: `auto 0`,
  display: `flex`,
  alignItems: `center`,

  [mqXLDesktop]: {
    right: `calc(((100vw - ${MAX_WIDTH}px) / 2) + 12px)`,
  },
  [mqTablet]: {
    right: sideMargin.tablet + 12,
  },
  [mqTabletHandheld]: {
    right: 9,
    position: `absolute`,
    bottom: `unset`,
    top: `auto`,
  },
})

const NavLink = styled(Link)({
  display: `block`,
  textAlign: `right`,
  whiteSpace: `nowrap`,
  fontFamily: `Recoleta`,

  fontSize: 93,
  "&:hover": {
    color: `rgb(219,172,40)`,
  },

  [mqTabletHandheld]: {
    lineHeight: `28px`,
    fontSize: 24,
    marginBottom: 15,
  },
})

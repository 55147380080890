// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotros-js": () => import("./../src/pages/nosotros.js" /* webpackChunkName: "component---src-pages-nosotros-js" */),
  "component---src-pages-privacidad-y-condiciones-js": () => import("./../src/pages/privacidad-y-condiciones.js" /* webpackChunkName: "component---src-pages-privacidad-y-condiciones-js" */),
  "component---src-pages-servicios-js": () => import("./../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-tranquilidad-js": () => import("./../src/pages/tranquilidad.js" /* webpackChunkName: "component---src-pages-tranquilidad-js" */)
}


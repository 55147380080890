import { css } from "@emotion/core"

export const tabletMinBP = 768
export const tabletMaxBP = 1024
export const desktopMaxBP = 1400
export const handheldBP = tabletMinBP - 1
export const mqHandheld = `@media(max-width: ${handheldBP}px)`
export const mqTablet = `@media (min-width: ${tabletMinBP}px) and (max-width: ${tabletMaxBP}px)`
export const mqTabletHandheld = `@media(max-width: ${tabletMaxBP}px)`
export const mqXLDesktop = `@media (min-width: ${desktopMaxBP}px)`

export const BACKGROUND = `158, 158, 159`
export const FONT = `black`
export const MAX_WIDTH = desktopMaxBP

export const sideMargin = {
  desktop: 75,
  tablet: 50,
  handheld: 18,
}

export default css`
  @font-face {
    font-family: "Graphik";
    src: url("/fonts/Graphik-Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "GraphikMedium";
    src: url("/fonts/Graphik-Medium.otf") format("opentype");
  }
  @font-face {
    font-family: "Recoleta";
    src: url("/fonts/Recoleta Regular.otf") format("opentype");
  }
  @font-face {
    font-family: "RecoletaLight";
    src: url("/fonts/Recoleta Light.otf") format("opentype");
  }
  html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: ${FONT};
    background: rgb(${BACKGROUND});
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  p {
    margin: 0;
    padding: 0;
  }
  button {
    margin: 0;
  }

  * {
    box-sizing: inherit;
  }
  *:before {
    box-sizing: inherit;
  }
  *:after {
    box-sizing: inherit;
  }
`

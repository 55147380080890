import { useRef, useEffect, useState } from "react"
import ResizeObserver from "resize-observer-polyfill"
import { useStaticQuery, graphql } from "gatsby"

export function useSiteMetadata() {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          email
          title
        }
      }
    }
  `)

  return {
    email: site.siteMetadata.email,
    title: site.siteMetadata.title,
  }
}

export function useMeasure() {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(
    () =>
      new ResizeObserver(([entry]) => {
        const rect = entry.target.getBoundingClientRect().toJSON()

        const cleanedRect = Object.keys(rect).reduce((obj, key) => {
          obj[key] = +rect[key].toFixed(2)
          return obj
        }, {})

        set(cleanedRect)
      })
  )
  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])
  return [{ ref }, bounds]
}

export function useWindowSize() {
  const isClient = typeof window === "object"

  function getSize() {
    return {
      width: isClient ? +window.innerWidth.toFixed(2) : undefined,
      height: isClient ? +window.innerHeight.toFixed(2) : undefined,
    }
  }

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) return false

    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener(`resize`, handleResize)
    return () => window.removeEventListener(`resize`, handleResize)
  }, [])
  return windowSize
}

export function useOnClickOutside(ref, handler) {
  useEffect(() => {
    function listener(e) {
      // Do nothing if clicking ref's element or child elements
      if (!ref.current || ref.current.contains(e.target)) return
      handler(e)
    }

    document.addEventListener("mousedown", listener)
    document.addEventListener("touchstart", listener)

    return () => {
      document.removeEventListener("mousedown", listener)
      document.removeEventListener("touchstart", listener)
    }
  }, [ref, handler])
}

export function useAnimationFrame(func, throttle) {
  const isRunning = useRef(null)
  const isClient = typeof window === "object"

  if (!isClient) return false

  return function (...args) {
    if (isRunning.current && throttle) return

    isRunning.current = true
    window.requestAnimationFrame(() => {
      isRunning.current = false
      func.apply(this, args)
    })
  }
}

import React from "react"
import PropTypes from "prop-types"
import { Global } from "@emotion/core"
import styled from "@emotion/styled"
import { Link, useStaticQuery, graphql } from "gatsby"
import { useMeasure, useSiteMetadata, useWindowSize } from "helpers/hooks"
import Header from "components/header"
import css, {
  BACKGROUND,
  MAX_WIDTH,
  mqHandheld,
  mqTablet,
  mqTabletHandheld,
  sideMargin,
} from "components/index.css"

const Layout = ({ children }) => {
  const { title } = useSiteMetadata()
  const { height: winHeight } = useWindowSize()
  const [bindHeader, { height: headerHeight }] = useMeasure()
  const [bindFooter, { height: footerHeight }] = useMeasure()

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  // function textAlign() {
  //   const isClient = typeof window === "object"
  //   if (!isClient) return
  //   const { pathname } = window.location
  //   return pathname.includes(`quienes-somos`) || pathname.includes(`servicios`)
  //     ? `right`
  //     : `left`
  // }

  return (
    <>
      <Global styles={css} />
      <Wrapper
        winHeight={winHeight}
        footerHeight={footerHeight}
        headerHeight={headerHeight}
      >
        <Header {...bindHeader} siteTitle={data.site.siteMetadata.title} />
        <main>
          {children}
          <Footer {...bindFooter}>
            <NavLink style={{ width: `33.3%` }} to="/contacto">
              Contacto &#38; redes
            </NavLink>
            <NavLink to="/privacidad-y-condiciones" side="center">
              Política de privacidad Términos &#38; condiciones
            </NavLink>
            <Copyright>&#169;{title}</Copyright>
          </Footer>
        </main>
      </Wrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

const Wrapper = styled.div(({ headerHeight, footerHeight, winHeight }) => ({
  margin: `0 ${sideMargin.desktop}px`,

  [mqTablet]: {
    margin: `0 ${sideMargin.tablet}px`,
    // padding: `70px 0`,
  },
  [mqHandheld]: {
    margin: `0 ${sideMargin.handheld}px`,
    // padding: `40px 0 0`,
  },
  "> main": {
    paddingBottom: footerHeight + 80,
    paddingTop: headerHeight + 70,
    minHeight: winHeight,
    display: `flex`,
    flexDirection: `column`,
    maxWidth: MAX_WIDTH,
    margin: `auto`,

    [mqTabletHandheld]: {
      paddingBottom: 0,
    },
    [mqHandheld]: {
      paddingTop: 40 + headerHeight,
    },
  },
  "&:before": {
    content: `''`,
    position: `fixed`,
    zIndex: 10,
    top: headerHeight,
    left: 0,
    width: `100%`,
    height: 70,
    backgroundImage: `linear-gradient(
      rgba(${BACKGROUND}, 1) 0%,
      rgba(${BACKGROUND}, 0) 100%
    )`,

    [mqHandheld]: {
      height: 40,
    },
  },
  "&:after": {
    content: `''`,
    position: `fixed`,
    zIndex: 5,
    bottom: footerHeight - 1,
    left: 0,
    width: `100%`,
    height: 70,
    backgroundImage: `linear-gradient(
      rgba(${BACKGROUND}, 0) 0%,
      rgba(${BACKGROUND}, 1) 100%
    )`,

    [mqTabletHandheld]: {
      display: `none`,
    },
  },
}))

const Footer = styled.footer({
  position: `fixed`,
  zIndex: 5,
  bottom: 0,
  left: 0,
  right: 0,
  padding: `0 75px 54px`,
  fontFamily: `Graphik`,
  fontSize: 13,
  color: `inherit`,
  display: `flex`,
  justifyContent: `space-between`,
  background: `rgb(${BACKGROUND})`,
  maxWidth: MAX_WIDTH + 150,
  margin: `auto`,

  [mqTabletHandheld]: {
    position: `static`,
    margin: 0,
  },
  [mqTablet]: {
    padding: `0 0 50px`,
    fontSize: 14,
    lineHeight: `25px`,
  },
  [mqHandheld]: {
    padding: `0 0 35px`,
    fontSize: 10,
    lineHeight: `13px`,
  },
})

const NavLink = styled(Link)(({ side = "left" }) => ({
  fontFamily: `Graphik`,
  color: `inherit`,
  textDecoration: `none`,
  transition: "all 0.2s linear",
  width: `33.3%`,
  textAlign: side,

  [mqTabletHandheld]: {
    width: `auto`,
    maxWidth: `50%`,
    textAlign: `left`,
    "&:first-of-type": {
      display: `none`,
    },
  },
}))

const Copyright = styled.p({
  width: `33.3%`,
  textAlign: `right`,

  [mqTabletHandheld]: {
    width: `auto`,
  },
})
